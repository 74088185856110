import React from 'react';
import Aux from "../../hoc/_Aux";
import '../../assets/scss/style.scss';
import {Button} from "react-bootstrap";
import Logo from "../components/Authentication/Logo";

const Page404 = () => {
    return (
        <Aux>
            <div className="auth-wrapper">
                <div style={{maxWidth: '980px', width: '100%', margin: '0px auto'}}
                     className='auth-container d-flex flex-column'>
                    <Logo/>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="row">
                            <div className="xs-12 md-6 mx-auto">
                                <div id="countUp">
                                    <div style={{fontSize: '40px'}} className="number text-center"
                                         data-count="404">404
                                    </div>
                                    <div className="text text-center">Page not found</div>
                                    <div className="text text-center">This may not mean anything.</div>
                                    <div className="text m-b-20 text-center">I'm probably working on something that has
                                        blown up.
                                    </div>
                                    <div className="text-center">
                                        <a href={'/'}>
                                            <Button
                                                className='btn-primary btn-sm'
                                            >Go to Home Page</Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    )
};

export default Page404;