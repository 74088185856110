export const URLS_LOCAL_GROUPS = {
    signIn: '/sign-in',
    signUp: '/sign-up',
    forgotPassword: '/forgot-password',
    reports: '/reports',
    security: '/security',
    passwordReset: '/password-reset/:uid/:token',
};

export const URLS_LOCAL_USER = {
    activity: '/activity',
    profile: '/profile',
    security: '/security',
    bankDetails: '/bank-details',
    personalInfo: '/personal-info',
    investments: '/investments',
    documents: '/documents',
    products: '/products',
    dashboard: '/dashboard',
    byToken: '/buy-token',
    platform: '/platform',
    myToken: '/my-token',
    transactions: '/transactions',
    referral: '/referral',
    confirmEmail: '/email-confirm',
    staking: {
        staking_products: '/staking/products',
        staking_deposits: '/staking/deposits',
        staking_payments: '/staking/payments',
    }
};

export const API_URLS = {
    setPassword: '/reset-password/client',
    confirmEmail: '/email-confirm/client',
    referralsList: '/client/ref-list',
    changePassword: '/client/change-password',
    addUserWallet: '/client/add-wallet',
    personalInfoUser: '/client/info',
    sendConfirmationLink: '/client/send-confirmation',
    userWallet: '/client/wallet',
    signIn: '/sign-in/client',
    signOut: '/sign-out/client',
    signUp: '/sign-up/client',
    clientProfile: '/profile',
    priceDataFeed: '/client/payment/price-chart',
    statistic: '/statistic',
    userDashboard: '/client/dashboard',
    getPaymentFormData: '/client/payment/form',
    getPromoCodeInfo: '/client/promo-code/form',
    createPaymentRequest: '/client/payment/create',
    paymentsList: '/client/payment/list',
    balances: '/client/payment/balance',
    wsRates: '/ws/rates',
    sendResetEmailLink: '/forgot-password/client',
    options: {
        currencies: '/client/currencies',
        transactionsOptions: '/client/constants',
    },
    staking: {
        products: '/client/staking',
        transactions: '/client/staking/payments',
        deposits: '/client/staking/deposits',
        createDeposit: '/client/staking/deposits/create',
        cancelDeposit: `/client/staking/deposits/cancel`,
        addHash: id => `/api/staking/deposits/${id}/`,
    }
};

export const buildAPIUrl = url => {
    if (process.env.NODE_ENV === "development") {
        return url
    } else {
        return process.env.REACT_APP_NODE_HTTP_HOST + url
    }
};

export const buildWSUrl = url => {
    if (process.env.NODE_ENV === "development") {
        return process.env.REACT_APP_NODE_WS_HOST_DEV + url
    } else {
        return process.env.REACT_APP_NODE_WS_HOST + url
    }
};