import * as dashboardActions from '../../actions/dashboardActions/constants'

const initialState = {
    chartDataFeed: null,
    statistic: null,
    isLoading: true,
    data: null,
    isDashboardDataLoading: true,
    isStatisticLoading: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case dashboardActions.GET_PRICE_CHART_DATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case dashboardActions.GET_PRICE_CHART_DATA_SUCCESS:
            return {
                ...state,
                chartDataFeed: action.payload,
                // chartDataFeed: {
                //     data: [
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //         0.005,
                //     ],
                //     labels: [
                //          1650123181895,
                //          1650126781895,
                //          1650130381895,
                //          1650133981895,
                //          1650137581895,
                //          1650141181895,
                //          1650144781895,
                //          1650148381895,
                //          1650151981895,
                //          1650155581895,
                //          1650159181895,
                //          1650162781895,
                //          1650166381895,
                //          1650169981895,
                //          1650173581895,
                //          1650177181895,
                //          1650180781895,
                //          1650184381895,
                //          1650187981895,
                //          1650191581895,
                //          1650195181895,
                //          1650198781895,
                //          1650202381895,
                //          1650205981895,
                //     ]
                // },
                isLoading: false
            };
        case dashboardActions.GET_PRICE_CHART_DATA_FAILURE:
            return {
                ...state,
                chartDataFeed: null,
                isLoading: false
            };
        case dashboardActions.GET_DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                isDashboardDataLoading: true
            };
        case dashboardActions.GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isDashboardDataLoading: false
            };
        case dashboardActions.GET_DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                data: null,
                isDashboardDataLoading: false
            };
        case dashboardActions.GET_STATISTIC_REQUEST:
            return {
                ...state,
                isStatisticLoading: true
            };
        case dashboardActions.GET_STATISTIC_SUCCESS:
            return {
                ...state,
                statistic: action.payload,
                isStatisticLoading: false
            };
        case dashboardActions.GET_STATISTIC_FAILURE:
            return {
                ...state,
                statistic: null,
                isStatisticLoading: false
            };
        default:
            return state
    }
}