import {useEffect} from 'react';
import { withRouter } from 'react-router';

const ScrollToTop = props => {

    const {location} = props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return (
        props.children
    );
};

export default withRouter(ScrollToTop);