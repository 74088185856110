export const GET_STAKING_PRODUCTS_REQUEST = 'GET_STAKING_PRODUCTS_REQUEST';
export const GET_STAKING_PRODUCTS_SUCCESS = 'GET_STAKING_PRODUCTS_SUCCESS';
export const GET_STAKING_PRODUCTS_FAILURE = 'GET_STAKING_PRODUCTS_FAILURE';

export const GET_STAKING_DEPOSITS_REQUEST = 'GET_STAKING_DEPOSITS_REQUEST';
export const GET_STAKING_DEPOSITS_SUCCESS = 'GET_STAKING_DEPOSITS_SUCCESS';
export const GET_STAKING_DEPOSITS_FAILURE = 'GET_STAKING_DEPOSITS_FAILURE';

export const GET_STAKING_DEPOSIT_REQUEST = 'GET_STAKING_DEPOSIT_REQUEST';
export const GET_STAKING_DEPOSIT_SUCCESS = 'GET_STAKING_DEPOSIT_SUCCESS';
export const GET_STAKING_DEPOSIT_FAILURE = 'GET_STAKING_DEPOSIT_FAILURE';

export const GET_STAKING_PRODUCT_REQUEST = 'GET_STAKING_PRODUCT_REQUEST';
export const GET_STAKING_PRODUCT_SUCCESS = 'GET_STAKING_PRODUCT_SUCCESS';
export const GET_STAKING_PRODUCT_FAILURE = 'GET_STAKING_PRODUCT_FAILURE';

export const CREATE_DEPOSIT_REQUEST = 'CREATE_DEPOSIT_REQUEST';
export const CREATE_DEPOSIT_SUCCESS = 'CREATE_DEPOSIT_SUCCESS';
export const CREATE_DEPOSIT_FROM_ERC20_SUCCESS = 'CREATE_DEPOSIT_FROM_ERC20_SUCCESS';
export const CREATE_DEPOSIT_FAILURE = 'CREATE_DEPOSIT_FAILURE';

export const CANCEL_DEPOSIT_REQUEST = 'CANCEL_DEPOSIT_REQUEST';
export const CANCEL_DEPOSIT_SUCCESS = 'CANCEL_DEPOSIT_SUCCESS';
export const CANCEL_DEPOSIT_FAILURE = 'CANCEL_DEPOSIT_FAILURE';

export const ADD_TRX_HASH_REQUEST = 'ADD_TRX_HASH_REQUEST';
export const ADD_TRX_HASH_SUCCESS = 'ADD_TRX_HASH_SUCCESS';
export const ADD_TRX_HASH_FAILURE = 'ADD_TRX_HASH_FAILURE';

export const GET_STAKING_TRANSACTIONS_REQUEST = 'GET_STAKING_TRANSACTIONS_REQUEST';
export const GET_STAKING_TRANSACTIONS_SUCCESS = 'GET_STAKING_TRANSACTIONS_SUCCESS';
export const GET_STAKING_TRANSACTIONS_FAILURE = 'GET_STAKING_TRANSACTIONS_FAILURE';

export const SET_DEFAULTS = 'SET_STAKING_DEFAULTS';
