import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

//reducers
import appReducer from './appReducer';
import user from './userReducer';
import options from './optionsReducer';
import payments from './paymentsReducer';
import token from './tokenReducer';
import dashboard from './dashboardReducer';
import staking from './stakingReducer';
import ws from './wsReducer';

const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['options'],
};

const rootReducer = combineReducers({
    appReducer,
    user,
    options,
    payments,
    token,
    dashboard,
    staking,
    ws,
});

export default persistReducer(rootPersistConfig, rootReducer)
