import * as investmentsActions from '../../actions/paymentsAction/constants'

const initialState = {
    payments: null,
    balances: null,
    isBalancesLoading: true,
    isLoading: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case investmentsActions.GET_PAYMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case investmentsActions.GET_PAYMENTS_SUCCESS:
            return {
                ...state,
                payments: action.payload,
                isLoading: false
            };
        case investmentsActions.GET_PAYMENTS_FAILURE:
            return {
                ...state,
                payments: null,
                isLoading: false
            };
        case investmentsActions.GET_BALANCES_REQUEST:
            return {
                ...state,
                isBalancesLoading: true,
            };
        case investmentsActions.GET_BALANCES_SUCCESS:
            return {
                ...state,
                balances: action.payload,
                isBalancesLoading: false
            };
        case investmentsActions.GET_BALANCES_FAILURE:
            return {
                ...state,
                balances: null,
                isBalancesLoading: false
            };
        case investmentsActions.SET_DEFAULTS:
            return {
                payments: null,
                balances: null,
                isBalancesLoading: true,
                isLoading: true
            };
        default:
            return state
    }
}