export const GET_PRICE_CHART_DATA_REQUEST = 'GET_PRICE_CHART_DATA_REQUEST';
export const GET_PRICE_CHART_DATA_SUCCESS = 'GET_PRICE_CHART_DATA_SUCCESS';
export const GET_PRICE_CHART_DATA_FAILURE = 'GET_PRICE_CHART_DATA_FAILURE';

export const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA_REQUEST';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE = 'GET_DASHBOARD_DATA_FAILURE';

export const GET_STATISTIC_REQUEST = 'GET_STATISTIC_REQUEST';
export const GET_STATISTIC_SUCCESS = 'GET_STATISTIC_SUCCESS';
export const GET_STATISTIC_FAILURE = 'GET_STATISTIC_FAILURE';