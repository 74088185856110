export const GET_CURRENCIES_REQUEST = 'GET_CURRENCIES_REQUEST';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILURE = 'GET_CURRENCIES_FAILURE';

export const GET_TRANSACTIONS_OPTIONS_REQUEST = 'GET_TRANSACTIONS_OPTIONS_REQUEST';
export const GET_TRANSACTIONS_OPTIONS_SUCCESS = 'GET_TRANSACTIONS_OPTIONS_SUCCESS';
export const GET_TRANSACTIONS_OPTIONS_FAILURE = 'GET_TRANSACTIONS_OPTIONS_FAILURE';

export const GET_STAKING_OPTIONS_REQUEST = 'GET_STAKING_OPTIONS_REQUEST';
export const GET_STAKING_OPTIONS_SUCCESS = 'GET_STAKING_OPTIONS_SUCCESS';
export const GET_STAKING_OPTIONS_FAILURE = 'GET_STAKING_OPTIONS_FAILURE';

