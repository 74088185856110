import React from 'react';
import {URLS_LOCAL_GROUPS, URLS_LOCAL_USER} from "./urls";

const ForgotPassword = React.lazy(() => import('./App/components/Authentication/ForgotPassword'));
const SignIn = React.lazy(() => import('./App/components/Authentication/SignIn'));
const SetNewPassword = React.lazy(() => import('./App/components/Authentication/SetNewPassword'));
const SignUp = React.lazy(() => import('./App/components/Authentication/SignUp'));
const ConfirmEmail = React.lazy(() => import('./App/components/Authentication/ConfirmEmail'));

const authRoutes = [
    { path: URLS_LOCAL_USER.confirmEmail + '/:uid?/:token?', exact: true, name: 'Confirm Email', component: ConfirmEmail },
    { path: URLS_LOCAL_GROUPS.forgotPassword, exact: true, name: 'Reset Password', component: ForgotPassword },
    { path: URLS_LOCAL_GROUPS.signIn, exact: true, name: 'Sign In', component: SignIn },
    { path: URLS_LOCAL_GROUPS.signUp, exact: false, name: 'Sign Up', component: SignUp },
    { path: URLS_LOCAL_GROUPS.passwordReset, exact: true, name: 'Set New Password', component: SetNewPassword },
];

export default authRoutes;