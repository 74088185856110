import * as wsAction from '../../actions/wsActions/constants'

const initialState = {
    wsStatus: 'close',
    message: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case wsAction.WS_ON_CONNECT:
            return {
                ...state,
                wsStatus: 'connecting'
            };
        case wsAction.WS_ON_OPEN:
            return {
                ...state,
                wsStatus: 'open'
            };
        case wsAction.WS_ON_CLOSE:
            return {
                ...state,
                wsStatus: 'close'
            };
        case wsAction.WS_ON_MESSAGE:
            return {
                ...state,
                message: action.payload
            };
        default:
            return state
    }
}